import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ระบบเกม"
    }}>{`ระบบเกม`}</h1>
    <hr></hr>
    <h3 {...{
      "id": "การออกสำรวจ"
    }}>{`การออกสำรวจ`}</h3>
    <p>{`ไอคอนออกสำรวจ จะสามารถกดได้ก็ต่อเมื่อรับเควสมาแล้วเท่านั้น ( ถ้ายังไม่รับได้รับเควส จะไม่สามารถส่งตัวละครออกไปสำรวจได้ )`}</p>
    <p><img alt="explore" src={require("./public/images/explore-1.png")} /></p>
    <p>{`เมื่อตัวละครออกสำรวจ ตัวละครจะไปรวบรวมหาวัตถุดิบที่ผู้เล่นเลือกไว้มาให้ ถ้าตัวละครไม่เคยออกสำรวจมาก่อน การออกสำรวจครั้งแรกจะไม่เสียค่าใช้จ่าย ( เฉพาะครั้งแรกเท่านั้น )`}</p>
    <p><img alt="explore" src={require("./public/images/explore-2.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      